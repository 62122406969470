/*
 * @Author: Matthew Auld
 * @Date: 2018-12-17 17:57:00
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2018-12-17 19:03:20
 * @File: TopBar.jsx
 * Copyright 2018 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";

class TopBar extends Component {
	state = {
		showCreateList: false
	};
	render() {
		return (
			<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
				<Link className="navbar-brand" to="/">
					{this.props.text}
				</Link>
				<button
					className="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#todoNavBar"
					aria-controls="todoNavBar"
					aria-expanded="false"
					aria-label="Toggle Navigation"
				>
					<FontAwesomeIcon icon={["fal", "bars"]} />
				</button>
				<div className="collapse navbar-collapse" id="todoNavBar">
					<ul className="navbar-nav ml-auto">
						<li className="nav-item">
							<Link
								to="/"
								className="nav-link"
								onClick={(e) => {
									$(".navbar-collapse").collapse("hide");
								}}
							>
								Home
							</Link>
						</li>
						<li className="nav-item">
							{this.props.showCreateButton ? (
								<button className="btn btn-success btn-block my-2 my-sm-0">
									Create New List
								</button>
							) : (
								""
							)}
						</li>
					</ul>
				</div>
			</nav>
		);
	}
}

export default TopBar;
