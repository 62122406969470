import React, { Component } from "react";
import $ from "jquery";
import "./Todo-Styler.scss";

class TodoListItem extends Component {
	state = { item_text: this.props.text };
	render() {
		return (
			<li className="todo-item">
				<input
					type="checkbox"
					className="styled-checkbox todo-check"
					id={"todo-item-" + this.props.id}
					defaultChecked={this.props.completed}
					onChange={this.handleChange}
					bind={this}
				/>
				<label htmlFor={"todo-item-" + this.props.id}>
					{this.state.item_text}
				</label>
			</li>
		);
	}

	handleChange = () => {
		const isChecked = $("#todo-item-" + this.props.id)[0].checked;
		const items = JSON.parse(localStorage.getItem("todo-items"));

		for (var i in items) {
			if (parseInt(items[i].id) === this.props.id) {
				items[i].completed = isChecked;
				break; //Stop this loop, we found it!
			}
		}
		localStorage.setItem("todo-items", JSON.stringify(items));
	};
}

export default TodoListItem;
