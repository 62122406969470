import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";

class NavigationPage extends Component {
	state = {
		todo_lists: null
	};

	render() {
		return (
			<div
				className="container"
				style={{ height: "calc(100% - 56px)" }}
				id="nav-page"
			>
				<div className="row h-100" id="todo-lists">
					{!this.state.todo_lists ? (
						<div
							className="col-12 text-center d-flex align-items-center justify-content-center"
							style={{ fontSize: 22 }}
						>
							<span>
								<FontAwesomeIcon
									icon={["far", "spinner-third"]}
									className="fa-spin mr-2"
								/>
								Loading from Database...
							</span>
						</div>
					) : (
						this.state.todo_lists.map((list) => (
							<div
								onClick={() => {
									$("#goto-list-" + list.id)[0].click();
								}}
								key={list.id}
								className="col-6 col-md-4 todo-lists"
							>
								<div>
									<Link
										to={"/list/" + list.id}
										id={"goto-list-" + list.id}
									>
										{list.name}
									</Link>
								</div>
							</div>
						))
					)}
				</div>
			</div>
		);
	}

	componentDidMount() {
		document.body.classList.value = "nav-page";
		this.props.onTitleChange("Do It");

		this.setState(function() {
			return {
				todo_lists:
					localStorage.getItem("todo-lists") === null ||
					localStorage.getItem("todo-lists") === undefined ||
					localStorage.getItem("todo-lists") === ""
						? []
						: JSON.parse(localStorage.getItem("todo-lists"))
			};
		});
		$("#todo-lists,#nav-page").removeClass("h-100");
	}
}

export default NavigationPage;
