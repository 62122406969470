/*
 * @Author: Matthew Auld
 * @Date: 2018-12-17 17:52:03
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2018-12-17 20:47:54
 * @File: ListPage.jsx
 * Copyright 2018 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import TodoList from "../components/list-items/Todo-List";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TodoListItem from "../components/list-items/Todo-ListItem";
class ListPage extends Component {
	state = {
		list_items: null
	};
	render() {
		console.log("Render ListPage...");
		return (
			<TodoList>
				{!this.state.list_items ? (
					<li
						className="text-center"
						style={{ fontSize: 22, paddingTop: 25 }}
					>
						<FontAwesomeIcon
							icon={["far", "spinner-third"]}
							className="fa-spin mr-2"
						/>
						Loading List Content...
					</li>
				) : (
					this.state.list_items.map((item, key) => {
						console.log("Render Item...");
						return (
							<TodoListItem
								text={item.text}
								completed={item.completed}
								key={key}
								id={item.id}
							/>
						);
					})
				)}
				{this.state.list_items ? (
					<li className="px-3 py-2">
						<input
							id="new-list-item"
							type="text"
							className="form-control"
							placeholder="Create new item..."
							onKeyPress={this.handleInput}
							bind={this}
						/>
					</li>
				) : (
					""
				)}
			</TodoList>
		);
	}

	handleInput = (e) => {
		const items = JSON.parse(localStorage.getItem("todo-items"));
		if (e.key === "Enter") {
			const item_text = $("#new-list-item").val();

			items.push({
				id: items.length + 1,
				list_id: parseInt(this.props.match.params.id),
				text: item_text
			});

			localStorage.setItem("todo-items", JSON.stringify(items));

			this.setState({
				list_items: items
			});

			$("#new-list-item").val("");
		}
	};

	componentDidMount() {
		document.body.classList.value = "nav-page";
		const list_id = parseInt(this.props.match.params.id);
		console.log("List ID:", list_id);
		const data = JSON.parse(localStorage.getItem("todo-lists"));
		const list = data.filter((i) => i.id === list_id)[0];

		if (list === undefined) {
			this.props.onTitleChange("Invalid List");
		} else {
			this.props.onTitleChange(list.name + " | List");
			const list_items = JSON.parse(localStorage.getItem("todo-items"));
			this.setState(() => {
				return {
					list_items: list_items.filter((i) => i.list_id === list_id)
				};
			});
		}
	}
}

export default ListPage;
