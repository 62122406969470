import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

if (!localStorage.getItem("todo-lists"))
	localStorage.setItem(
		"todo-lists",
		JSON.stringify([
			{
				id: 1,
				name: "Work"
			}
		])
	);

if (!localStorage.getItem("todo-items"))
	localStorage.setItem(
		"todo-items",
		JSON.stringify([{ id: 1, list_id: 1, text: "Do More Code..." }])
	);

ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	document.getElementById("root")
);

// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
