import React, { Component } from "react";
import { Route } from "react-router-dom";
import "./App.css";

import Header from "./components/TopBar";

/* ---- Page ---- */
import NavPage from "./pages/NavPage";
import ListPage from "./pages/ListPage";

/* ---- FontAwesome Icons ---- */
import { library } from "@fortawesome/fontawesome-svg-core";
// import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { faListAlt, faBars } from "@fortawesome/pro-light-svg-icons";

library.add(faListAlt, faSpinnerThird, faBars);

const renderMergedProps = (component, ...rest) => {
	const finalProps = Object.assign({}, ...rest);
	return React.createElement(component, finalProps);
};

const PropsRoute = ({ component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(routeProps) => {
				return renderMergedProps(component, routeProps, rest);
			}}
		/>
	);
};

/**
 * @class App
 * @extends Component
 */
class App extends Component {
	state = {
		header_text: "Do It",
		showCreateButton: true
	};
	render() {
		console.log("Render Main App");
		return (
			<div className="todo-application">
				<Header
					text={this.state.header_text}
					showCreateButton={this.state.showCreateButton}
				/>
				<PropsRoute
					path="/"
					exact
					component={NavPage}
					onTitleChange={this.setHeaderText}
				/>
				<PropsRoute
					path={"/list/:id"}
					component={ListPage}
					onTitleChange={this.setHeaderText}
				/>
			</div>
		);
	}

	setHeaderText = (text) => {
		this.setState(function() {
			return {
				header_text: text,
				showCreateButton: text === "Do It"
			};
		});
	};
}

export default App;
